import { I18n } from "react-redux-i18n";
import LayerList from "../../../layers/LayerList";
import LayerListItem from "../../../layers/LayerListItem";

const RenderContent = ({
  activeView,
  layers,
  onZoomToLayer,
  onLayerClick,
  initOpened,
  layerListTitle,
  isLoading,
  error,
}: {
  activeView: __esri.MapView | __esri.SceneView;
  layers: __esri.Layer[] | undefined;
  onZoomToLayer?: (layer: __esri.Layer) => void;
  onLayerClick?: any;
  initOpened?: boolean;
  layerListTitle: string;
  isLoading: boolean;
  error: string | undefined;
}) => {
  const info3d =
    activeView === null || layers === undefined || isLoading
      ? I18n.t("shared.loading")
      : undefined;
  const info =
    activeView.type === "2d"
      ? I18n.t("sidebar.projectPlanning.not2DSupperted")
      : info3d;

  return (
    <LayerList
      title={layerListTitle}
      errorMessage={error}
      infoMessage={info}
      initOpened={initOpened}
    >
      {layers?.map((layer) => (
        <LayerListItem
          key={layer.id}
          layer={layer}
          onZoomToLayer={onZoomToLayer}
          onLayerClick={onLayerClick}
        />
      ))}
    </LayerList>
  );
};

export default RenderContent;
