import { useContext } from "react";
import LayerList, { LayerListType } from "../../../layers/LayerList";
import Submenu from "../Submenu";
import SubmenuTitle from "../SubmenuTitle";
import { I18n } from "react-redux-i18n";
import ArcGISViewContext from "../../../../context/ArcGISViewContext";
import { ServiceType } from "../../../../config/AppConfig";
import { useCreateLoadAGSLayers } from "../../../../hooks/useCreateLoadAGSLayers";
import RenderContent from "./RenderContent";

const ProjectPlanning = () => {
  // hardcodes layers for now. must be factored to fetch layers from (config or) Portal
  const sceneLayerIdsWBS1 = [
    { portalId: "206da8ad9f7e4b7990acd8fe4a6fafc2", type: ServiceType.Scene },
    { portalId: "2ef558647b3d481786c5fea2277c4b88", type: ServiceType.Scene },
    { portalId: "967b7a005f20454daf9d4cc7d55a1c58", type: ServiceType.Scene },
    { portalId: "2186b7595e4f4cf9980427d1e80dbdf5", type: ServiceType.Scene },
    { portalId: "9f09bd918da745e79fe10efa094b8cfa", type: ServiceType.Scene },
    { portalId: "6e9cee5edd2c45afaeb876b72d1090ea", type: ServiceType.Scene },
    { portalId: "aa93a8f35fe747a89ebfd30437721263", type: ServiceType.Scene },
  ];

  const elevationLayerIdsWBS1 = [
    {
      portalId: "11b8e269e0834fa2aadbe930aa9468f5",
      type: ServiceType.ElevationLayer,
    },
  ];

  const sceneLayerIdsWBS2 = [
    { portalId: "9fd1a3b79ea44ff880956a1c2be3b3c2", type: ServiceType.Scene },
    { portalId: "837dff91c9ea4e8fb807459b1f3efdf7", type: ServiceType.Scene },
  ];

  const elevationLayerIdsWBS2 = [
    {
      portalId: "26083618606741a9a75408be01e177ea",
      type: ServiceType.ElevationLayer,
    },
  ];

  const { activeView } = useContext(ArcGISViewContext);
  const {
    loadedLayers: sceneLayersWBS1,
    loading: sceneLayersLoadingWBS1,
    error: sceneLayersErrorWBS1,
  } = useCreateLoadAGSLayers(sceneLayerIdsWBS1, "3d");
  const { loadedLayers: elevationLayersWBS1 } = useCreateLoadAGSLayers(
    elevationLayerIdsWBS1,
    "3d"
  );

  const {
    loadedLayers: sceneLayersWBS2,
    loading: sceneLayersLoadingWBS2,
    error: sceneLayersErrorWBS2,
  } = useCreateLoadAGSLayers(sceneLayerIdsWBS2, "3d");
  const { loadedLayers: elevationLayersWBS2 } = useCreateLoadAGSLayers(
    elevationLayerIdsWBS2,
    "3d"
  );

  const onZoomToLayer = (layer: __esri.Layer) => {
    if (layer.fullExtent) {
      activeView.goTo(layer.fullExtent);
    }
  };

  const onLayerClick = (
    layer: __esri.Layer,
    sceneLayers: __esri.Layer[],
    elevationLayers: __esri.Layer[]
  ) => {
    if (activeView && activeView.type === "3d") {
      layer.visible = !layer.visible;
      const isEverySceneLayerInvisible = sceneLayers.every(
        (sceneLayer) => !sceneLayer.visible
      );
      elevationLayers.forEach((elevationLayer) => {
        elevationLayer.visible = !isEverySceneLayerInvisible;
      });
      if (activeView.map.findLayerById(layer.id) == null) {
        activeView.map.add(layer);
        elevationLayers.forEach((elevationLayer) =>
          activeView.map.ground.layers.add(
            elevationLayer as __esri.ElevationLayer
          )
        );
      }
    }
  };

  return (
    <Submenu className="submenu-layers">
      <SubmenuTitle text={I18n.t("sidebar.projectPlanning.title")} />
      <div className="sidebar-submenu-content">
        <LayerList
          title={I18n.t("sidebar.projectPlanning.wbs.step1")}
          initOpened={true}
          layerListType={LayerListType.Top}
        >
          <RenderContent
            activeView={activeView}
            layers={sceneLayersWBS1}
            onZoomToLayer={onZoomToLayer}
            onLayerClick={(layer: __esri.Layer) =>
              onLayerClick(layer, sceneLayersWBS1, elevationLayersWBS1)
            }
            initOpened={true}
            layerListTitle={I18n.t(
              "sidebar.projectPlanning.status.workingBasis"
            )}
            isLoading={sceneLayersLoadingWBS1}
            error={sceneLayersErrorWBS1}
          />
        </LayerList>
        <LayerList
          title={I18n.t("sidebar.projectPlanning.wbs.step2")}
          initOpened={true}
          layerListType={LayerListType.Top}
        >
          <RenderContent
            activeView={activeView}
            layers={sceneLayersWBS2}
            onZoomToLayer={onZoomToLayer}
            onLayerClick={(layer: __esri.Layer) =>
              onLayerClick(layer, sceneLayersWBS2, elevationLayersWBS2)
            }
            initOpened={true}
            layerListTitle={I18n.t(
              "sidebar.projectPlanning.status.workingBasis"
            )}
            isLoading={sceneLayersLoadingWBS2}
            error={sceneLayersErrorWBS2}
          />
        </LayerList>
      </div>
    </Submenu>
  );
};

export default ProjectPlanning;
