import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Collapse, Typography } from "@mui/material";
import { ReactNode, useState } from "react";

export enum LayerListType {
  Top = 0,
  Sub = 1,
}

const LayerList = (props: {
  title: string;
  infoMessage?: string;
  errorMessage?: string;
  initOpened?: boolean;
  layerListType?: LayerListType;
  children: ReactNode;
}) => {
  const {
    title,
    errorMessage,
    infoMessage,
    children,
    initOpened,
    layerListType,
  } = props;
  const [open, setOpen] = useState(initOpened);

  return (
    <>
      <div
        onClick={() => setOpen(!open)}
        style={{
          padding: "5px 20px",
          display: "flex",
          justifyContent: "space-between",
          cursor: "pointer",
        }}
      >
        <Typography
          sx={{
            color: "text.primary",
            fontWeight: "bold",
            fontSize: layerListType === LayerListType.Top ? "20px" : "16px",
          }}
        >
          {title}
        </Typography>
        {open ? <ExpandLess /> : <ExpandMore />}
      </div>
      <Collapse in={open}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {infoMessage && (
            <div className="info">
              <Typography>{infoMessage}</Typography>
            </div>
          )}

          {errorMessage && (
            <div className="warning">
              <Typography>{errorMessage}</Typography>
            </div>
          )}

          {!infoMessage && !errorMessage && children}
        </div>
      </Collapse>
    </>
  );
};

export default LayerList;
