import { useContext, useEffect, useState } from "react";
import { ServiceType } from "../config/AppConfig";
import ArcGISViewContext from "../context/ArcGISViewContext";
import MapServiceFactory from "../map/MapServiceFactory";

export interface useHookLayer {
  portalId: string;
  type: ServiceType;
}

// keep cache of loaded layers.
// Possible refactoring is to add all alyers to map as visible false and then toggle visibility
let useCreateLoadAGSLayersCache: { [key: string]: __esri.Layer } = {};

/**
 * useCreateLoadAGSLayers Hook
 * @usage : useCreateLoadAGSLayers(portalLayerIds, type, loadOnInit);
 * @description : Custom hook used for loading a list of map service layers, based on portalItemsIds and type of mapservice.
 * @parameters : useHookLayer[] is a list of portalItems Ids and its mapservice type.
 * @parameters : dimension is 2d or 3d view.
 * @parameters : loadOnInit is if layer should load before return.
 * @parameters : addLayerToMap is if layer should be added to map (loaded).
 */
export function useCreateLoadAGSLayers(
  useHookLayers: useHookLayer[],
  dimension: "2d" | "3d",
  loadOnInit: boolean = true,
  addLayerToMap: boolean = true
) {
  const { activeView } = useContext(ArcGISViewContext);
  const [loadedLayers, setLoadedLayers] = useState<__esri.Layer[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const loadLayers = async () => {
    // internal function to load layer
    const createAndLoadLayers = useHookLayers.map(async (useHookLayer) => {
      setLoading(true);
      const { portalId, type } = useHookLayer;

      // is in map
      if (activeView.map.findLayerById(portalId) != null) {
        return activeView.map.findLayerById(portalId) as __esri.Layer;
      }

      // is in cache
      if (useCreateLoadAGSLayersCache[portalId]) {
        return useCreateLoadAGSLayersCache[portalId];
      }

      console.debug("useCreateLoadAGSLayers::Create layers", loadedLayers);
      const layer = MapServiceFactory.create({
        type: type,
        properties: {
          id: portalId,
          visible: false,
          portalItem: {
            id: portalId,
          },
        } as __esri.LayerProperties,
      });

      // if not loadin on init, just return layer
      if (!loadOnInit) {
        return layer;
      }

      // load layer and maybe add to map
      if (loadOnInit || addLayerToMap) {
        // load layer
        (await layer.load()) as Promise<__esri.Layer>;
        useCreateLoadAGSLayersCache[portalId] = layer;

        if (addLayerToMap) {
          if (layer.type === "elevation") {
            activeView.map.ground.layers.add(layer as __esri.ElevationLayer);
          } else {
            activeView.map.add(layer);
          }
        }
      }

      return layer;
    });

    try {
      const loadedLayers = await Promise.all(createAndLoadLayers);
      const sortedLayers = loadedLayers.toSorted((a: any, b: any) =>
        a.title.localeCompare(b.title)
      );
      setLoading(false);
      setLoadedLayers(sortedLayers);
    } catch (error) {
      console.error("useCreateLoadAGSLayers::Failed to load layers", error);
      setError("Failed to load layers. Error: " + error);
    }
  };

  // trigger useeffect when layerIds are added
  useEffect(() => {
    if (
      activeView &&
      activeView.type === dimension &&
      loadedLayers.length === 0 &&
      useHookLayers.length > 0
    ) {
      loadLayers();
    }
  }, [useHookLayers]);

  return {
    loadedLayers,
    loading,
    error,
  };
}
