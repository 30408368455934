import ZoomToLayerIcon from "../../assets/icons/layer-zoom-to.svg?react";
import { IconButton, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { StoreState } from "../../store/rootReducer";
import theme from "../../styles/theme";
import { useState } from "react";
import Layer from "@arcgis/core/layers/Layer";
import WMSSublayer from "@arcgis/core/layers/support/WMSSublayer";

const LayerListItem = (props: {
  layer: Layer | WMSSublayer;
  onZoomToLayer?: (layer: Layer) => void;
  onLayerClick?: (layer: Layer) => void;
}) => {
  const { layer, onZoomToLayer, onLayerClick } = props;
  const [visible, setVisible] = useState(layer.visible);
  const darkmode = useSelector((state: StoreState) => state.app.darkmode);

  return (
    <div
      title={(layer as any).name ?? layer.title ?? layer.id ?? "ukjent navn"}
      onClick={() => {
        if (onLayerClick) {
          onLayerClick(layer as Layer);
        }
        setVisible(!visible);
      }}
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: visible
          ? theme(darkmode).palette.secondary.dark
          : "transparent",
        borderLeft: visible
          ? `3px solid ${theme(darkmode).palette.primary.main}`
          : "none",
        padding: "5px 20px",
        cursor: "pointer",
      }}
    >
      <Typography
        sx={{
          color: "text.primary",
          minWidth: 0,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {(layer as any).name ?? layer.title ?? layer.id ?? "ukjent navn"}
      </Typography>
      <div style={{ display: "flex", flexDirection: "row", columnGap: "10px" }}>
        {layer.fullExtent && onZoomToLayer && (
          <IconButton
            onClick={(event) => {
              onZoomToLayer(layer as Layer);
              event.stopPropagation();
            }}
          >
            <ZoomToLayerIcon
              width={18}
              height={18}
              fill={theme(darkmode).palette.primary.main}
            />
          </IconButton>
        )}
      </div>
    </div>
  );
};

export default LayerListItem;
