import { useContext, useEffect, useRef } from "react";
import Map from "@arcgis/core/Map";
import MapView from "@arcgis/core/views/MapView";
import { useDispatch } from "react-redux";
import * as reactiveUtils from "@arcgis/core/core/reactiveUtils.js";
import LoadingIndicator from "./LoadingIndicator";
import { setLoading } from "../store/appSlice";
import appConfig, { Dimension } from "../config/AppConfig";
import MapServiceFactory from "../map/MapServiceFactory";
import {
  addBasemapGallery,
  addCrossSectionWidget,
  addLocate,
  moveWidget,
} from "../map/MapWidgetFactory";
import ViewToggleButton from "./basic/ViewToggleButton";
import SceneView from "@arcgis/core/views/SceneView";
import ArcGISViewContext from "../context/ArcGISViewContext";

// View state set to context, "ArcGISViewContext"
const MapComponent = () => {
  const mapDivRef = useRef(null);
  const dispatch = useDispatch();
  const { setMapView, activeView, setActiveView, setSceneView } =
    useContext(ArcGISViewContext);
  // const [selectedFeature, setSelectedFeature] = useState<Graphic | null>(null)

  // on activeView change, set loading state
  useEffect(() => {
    if (activeView == null) {
      return;
    }
    reactiveUtils.watch(
      () => activeView.updating,
      (updating) => {
        // console.debug("updating ActiveView:", updating)
        dispatch(setLoading(updating));
      }
    );
  }, [activeView]);

  // on init creta map, mapView and sceneView
  useEffect(() => {
    if (mapDivRef.current == null) return;

    // 2D map and view. Initial 2D is visible
    const layers = appConfig.layers
      .filter(
        (service) =>
          service.dimension == Dimension.Only2D ||
          service.dimension == Dimension.Both
      )
      .map((service) => MapServiceFactory.create(service));

    const map = new Map({
      basemap: MapServiceFactory.createBasemap(appConfig.map2D.default.basemap),
      layers: layers,
    });

    const mapView = new MapView({
      container: mapDivRef.current,
      map: map,
      extent: appConfig.map2D.default.extent,
      zoom: appConfig.map2D.default.zoom,
      ui: {
        components: ["attribution", "zoom"],
      },
    });

    mapView.when(() => {
      setMapView(mapView);
      setActiveView(mapView);

      mapView.popup.visibleElements = {
        featureNavigation: false,
        actionBar: false,
      };
    });

    // 3D map and view. Initial 3D is not visible
    const layers3d = appConfig.layers
      .filter(
        (service) =>
          service.dimension == Dimension.Only3D ||
          service.dimension == Dimension.Both
      )
      .map((service) => MapServiceFactory.create(service));

    const groundLayers = appConfig.map3D.default.ground.layers.map((service) =>
      MapServiceFactory.create(service)
    );
    const map3d = new Map({
      basemap: MapServiceFactory.createBasemap(appConfig.map3D.default.basemap),
      ground: {
        layers: groundLayers,
        navigationConstraint:
          appConfig.map3D.default.ground.navigationConstraint,
        opacity: appConfig.map3D.default.ground.opacity,
      },
      layers: layers3d,
    });

    const sceneView = new SceneView({
      container: undefined,
      map: map3d,
      viewingMode: "local",
      qualityProfile: "high",
      extent: appConfig.map3D.default.extent,
      zoom: appConfig.map3D.default.zoom,
      popupEnabled: false, // disabled popup due to performance issues
      ui: {
        components: ["attribution", "zoom"],
      },
    });
    mapView.when(() => {
      setSceneView(sceneView);
    });

    // add widgets to Views
    addBasemapGallery([mapView, sceneView], "top-right");
    addLocate([mapView, sceneView], "top-right");
    moveWidget([mapView, sceneView], "zoom", "bottom-right", "horizontal");
    addCrossSectionWidget([mapView, sceneView], "top-right");

    /* reactiveUtils.watch(
      () => view.popup.selectedFeature,
      (feature) => {
        setSelectedFeature(feature)
      },
    ) */

    return () => {
      MapServiceFactory.dispose();
      mapView?.destroy();
      sceneView?.destroy();
      activeView?.destroy();
    };
  }, [mapDivRef]);

  return (
    <>
      <div className="mapDiv" ref={mapDivRef}>
        <ViewToggleButton container={mapDivRef} />
      </div>
      <LoadingIndicator />
      {/* <PopupPortal mount={popupRoot}>
        <MachinePopup feature={selectedFeature}></MachinePopup>
      </PopupPortal>
      <PopupPortal mount={popupTitleRoot}>
        <MachinePopupTitle feature={selectedFeature}></MachinePopupTitle>
      </PopupPortal>
      <NoteEffects /> */}
    </>
  );
};

export default MapComponent;
