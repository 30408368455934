import { combineReducers } from "redux"
import app, { AppState } from "./appSlice"
import menu, { MenuState } from "./menuSlice"
import bookmarks, { BookmarkState } from "./bookmarkSlice"
import hmsk, { HMSKState } from "./HMSKSlice"
import profile, { ProfileState } from "./profileSlice"
import notes, { NotesGlobalState } from "./notesSlice"
import { i18nReducer } from "react-redux-i18n"

const rootReducer = combineReducers({
  app,
  menu,
  hmsk,
  bookmarks,
  profile,
  notes,
  i18n: i18nReducer,
})

export default rootReducer

export interface StoreState {
  app: AppState
  menu: MenuState
  hmsk: HMSKState
  bookmarks: BookmarkState
  profile: ProfileState
  notes: NotesGlobalState,
}
