import { useContext, useState } from "react";
import Icon2D from "../../assets/icons/2d-icon.svg?react";
import Icon3D from "../../assets/icons/3d-icon.svg?react";
import ArcGISViewContext from "../../context/ArcGISViewContext";

const ViewToggleButton = (props: { container: any }) => {
  const divElement = props.container;
  const { mapView, activeView, setActiveView, sceneView } =
    useContext(ArcGISViewContext);
  const [is3D, setIs3D] = useState(
    activeView ? activeView.type === "3d" : false
  );

  const handleClick = () => {
    if (divElement.current == null) return;

    const activeViewpoint = activeView.viewpoint.clone();
    // remove the reference to the container for the previous view
    (activeView.container as any) = null;

    if (is3D) {
      // if the input view is a SceneView, set the viewpoint on the
      // mapView instance. Set the container on the mapView and flag
      // it as the active view
      mapView.viewpoint = activeViewpoint;
      (mapView.container as any) = divElement.current;
      setActiveView(mapView);
    } else {
      sceneView.viewpoint = activeViewpoint;
      (sceneView.container as any) = divElement.current;
      setActiveView(sceneView);
    }
    setIs3D(!is3D);
  };

  return (
    <button
      type="button"
      onClick={handleClick}
      style={{
        position: "absolute",
        bottom: "4.5rem",
        right: "1rem",
        opacity: 0.4,
        width: "3rem",
        height: "3rem",
        borderRadius: "50%",
        backgroundColor: "#FFF",
        zIndex: 1000,
      }}
    >
      {is3D ? <Icon2D /> : <Icon3D />}
    </button>
  );
};

export default ViewToggleButton;
